import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Heimdall from '../src/components/Heimdall/Heimdall';
import TitleH2 from '../src/components/Titles/TitleH2/TitleH2';
import { Freya } from '../src/components/Freya/Freya';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Hod from '../src/components/Hod/Hod';
import './gabinete_prensa.scss';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query gabineteQuery($locale: String!) {
    allGabineteSeoBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allGabineteTitleBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
          subtitle
        }
      }
    }
    allGabineteHeaderImage(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          image
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allGabineteBreadcrumb(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          name
        }
      }
    }
    allGabineteListBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allGabineteListItems(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          characteristics
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allGabineteRichData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class GabinetePrensa extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allGabineteTitleBlock.edges[0].node.name,
      image: {
        url: this.props.data.allGabineteHeaderImage.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allGabineteHeaderImage.edges[0].node.alt,
      },
    };

    const titleData = {
      title: this.props.data.allGabineteTitleBlock.edges[0].node.subtitle,
    };

    const prepareFreyaData = () => {
      return {
        cards: this.props.data.allGabineteListItems.edges
          .map((item) => ({
            image: item.node.localImage.childImageSharp.fluid,
            altImage: 'contacto-de-prensa',
            title: item.node.title,
            titleType: 'h3',
            description: item.node.description,
            imgsize: 'large',
            buttons: {
              cta: false,
            },
            fenrirData: {
              list: item.node.characteristics,
            },
          }))
          .filter((item) => !!item.title),
      };
    };

    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allGabineteSeoBlock.edges[0].node._0.title}
          description={this.props.data.allGabineteSeoBlock.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allGabineteRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allGabineteHeaderImage.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="gabinete-container">
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allGabineteBreadcrumb.edges[0].node.name}
              />
              <TitleH2 data={titleData} />
              <Freya data={prepareFreyaData()} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default GabinetePrensa;
